/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/hyperacusis/'], ['en', 'https://www.hear.com/hearing-loss/hyperacusis/'], ['en-US', 'https://www.hear.com/hearing-loss/hyperacusis/'], ['en-CA', 'https://ca.hear.com/hearing-loss/hyperacusis/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-diagnosis-treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-diagnosis-treatment",
    "aria-label": "symptoms diagnosis treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms, diagnosis, treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The medical term hypoacusis means simply hearing impairment, a partial or total inability to hear. This hearing loss becomes obvious in many routine situations: Do you have trouble understanding conversations at a restaurant? Do you have a hard time communicating in loud environments? Or, do family and friends constantly remind you that the TV is set too loud? If so, there is reason to believe you suffer from hypoacusis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following contains important information pertaining to the topic of hearing impairment (hypoacusis). Our hearing aid experts will gladly answer any questions you might have over the phone and advise you individually regarding your hearing loss. Simply give us a call."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It is a common misconception that hypoacusis only occurs in older people in the form of age-related hearing loss. In fact, this hearing impairment can affect all age groups: One in four Canadians is affected by it in the course of his or her lifetime. Usually, hearing loss develops very slowly and the affected don’t even notice at first. The hearing spectrum increasingly narrows – and the brain loses the ability to remember how wide the spectrum actually was before."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signs of hearing loss are: Trouble hearing in a loud environment (for example at the restaurant or in the subway), cumbersome communication with several participants, subdued perception of other voices, and over time, having to adjust radio or TV to a higher volume. Those who are unsure if changes did occur in their personal auditory perception should ask friends and family to give them honest feedback: Did they recognize changes in your listening habits?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "This is how hearing aids help with hypoacusis…")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids can successfully alleviate hypoacusis at any age and hence substantially improve quality of life for the wearer.\nFellow human beings – individually or in a group – are understood more easily.\nAmbient noises and acoustic danger signals can be classified more easily.\nSocial inclusion is enabled and isolation prevented.\nGenerally, the sooner the use of hearing aids is implemented, the sooner a more severe hearing loss usually can be prevented."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosing-hypoacusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosing-hypoacusis",
    "aria-label": "diagnosing hypoacusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosing hypoacusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An ENT-physician, who performs an ear exam and a hearing test, diagnoses hearing loss. Based on the hearing test, an audiogram is made: This is the graphic representation of frequency ranges, where sounds cannot be heard anymore. With age-related hearing loss (presbyacusis) primarily the high-pitched tones are affected. A hearing aid audiologist can also perform a professional hearing test and offer competent advice. hear.com can help you finding a hearing care professional in your vicinity. Contact us!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "classification-of-hypoacusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#classification-of-hypoacusis",
    "aria-label": "classification of hypoacusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Classification of hypoacusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing impairments can be broadly divided into one of the two following types:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Conductive hearing loss develops in the outer or middle ear (middle ear deafness)."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sensorineural hearing loss, on the contrary, develops in the inner ear or in the auditory nerves (auditory nerve deafness = neural hearing impairment)."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A healthy auditory process involves firstly the so-called conduction via air, where the sound travels through the outer, middle, and inner ear, and secondly conduction via bone, where sound signals are directly conducted through the skull bone. With a disorder of the sensorineural aspect, both pathways are equally hindered, because the problem arises at the level of the inner ear. The translation of sound waves arriving at the inner ear into acoustically perceivable sounds is disrupted."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By contrast, experts speak of a disorder of the conductive aspect of sound transmission when this transmission occurs problematically over air but properly over bone. There is a great number of possible causes. Anything that interferes with the transmission of sound on its way from the outer to the inner ear can be a cause. For example, a foreign body in the ear canal, a middle ear effusion, or a problem with sound transmission between the eardrum and ossicles."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-of-hypoacusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-of-hypoacusis",
    "aria-label": "treatment of hypoacusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment of hypoacusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In most cases, modern hearing aids can alleviate hearing loss: Modern technology makes an almost natural, spatial 360° hearing possible. Meanwhile, these hearing systems are made so small and light that others can hardly notice them anymore. We distinguish between devices that are placed behind the ear (BTE, behind the ear) and those directly placed in the ear canal (ITE, in the ear). Our hearing aid experts advise you extensively during the selection process of a personally fitted hearing device – individually, objectively, and free of charge."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
